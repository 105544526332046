<!--
 * @Author: your name
 * @Date: 2021-04-25 11:06:31
 * @LastEditTime: 2021-05-16 17:41:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\profile\index.vue
-->
<template>
  <div>
    <navigation msg="职业档案">
      <div @click="$router.push('/staffadd')" class="addyuan">
        <van-icon color="#F44E06" size=".4rem" name="add-o" />
        <div>添加员工</div>
      </div>
    </navigation>
    <div class="topmsg">
      <div class="seleclass">
        <input
          v-model="selemsg"
          type="text"
          name=""
          placeholder="姓名/手机号码"
          id=""
        />
        <van-icon @click.stop="selemeth" size=".4rem" name="search" />
      </div>
      <div @click="showPicker = true" class="sh2">
        {{ selmm }}<img src="../../assets/sanjiao.png" />
        <div v-show="selenum != 0" class="tiaojian">{{ selenum }}</div>
      </div>
    </div>
    <div class="msgclass">
      <div
        @click="tometh(item)"
        v-for="(item, index) in allmsg"
        v-bind:key="index"
        class="themsgdd"
      >
        <div class="themsgdd1">
          <img
            v-if="(item.sex + '').indexOf('男') != -1"
            class="themsgdd2"
            src="../../assets/user_1.png"
            alt=""
          />
          <img
            v-else-if="(item.sex + '').indexOf('女') != -1"
            class="themsgdd2"
            src="../../assets/user_2.png"
            alt=""
          />
          <img v-else class="themsgdd2" src="../../assets/user_1.png" alt="" />
          <div class="themsgdd2_2">
            <div class="themsgdd3">
              <div class="themsgdd4">
                <div>{{ item.staff_name }}</div>
                <div>{{ item.jop }}</div>
              </div>
              <div class="themsgdd5">
                <div
                  :style="
                    item.survey == 1
                      ? 'background:#979797'
                      : item.survey == 2
                      ? 'background:#16ca4d'
                      : item.survey == 3
                      ? 'background:#fa4d08'
                      : item.survey == 4
                      ? 'background:#088ffa'
                      : item.survey == 7
                      ? 'background:#16ca4d'
                      : 'background:#979797'
                  "
                >
                  {{
                    item.survey == 1
                      ? "未背调"
                      : item.survey == 2
                      ? "背调中"
                      : item.survey == 3
                      ? "被拒绝"
                      : item.survey == 4
                      ? "已背调"
                      : item.survey == 7
                      ? "待支付"
                      : "未背调"
                  }}
                </div>
                <div
                  :style="
                    item.status == 2
                      ? 'background:#00BEFF'
                      : 'background:#96a5aa'
                  "
                >
                  {{
                    item.status == 1
                      ? "未入职"
                      : item.status == 2
                      ? "已入职"
                      : item.status == 3
                      ? "已离职"
                      : item.status == 4
                      ? "拒绝入职"
                      : item.status == 5
                      ? "离职确认中"
                      : item.status == 6
                      ? "入职确认中"
                      : item.status == 7
                      ? "拒绝离职"
                      : "获取失败"
                  }}
                </div>
              </div>
            </div>
            <div class="themsgdd6">手机号码：{{ item.mobile }}</div>
          </div>
        </div>
        <div class="themsgdd7">
          <div>入职时间：{{ item.add_time }}</div>
          <div>
            累计职业评价：<span class="themsgdd8">{{
              item.evaluateCount
            }}</span>
          </div>
        </div>
      </div>
      <!-- <div
        @click="tometh(item)"
        v-for="(item, index) in allmsg"
        v-bind:key="index"
        class="msgclass2"
      >
        <div class="msgimg"><img src="../../assets/user_1.png" alt="" /></div>
        <div class="rightmsg">
          <div class="naticlass">
            <div>
              <span>{{ item.staff_name }}</span
              ><span>{{ item.jop }}</span>
            </div>
            <div>入职时间：{{ (item.entry_time + "").split(" ")[0] }}</div>
          </div>
          <div class="bottomclass">
            <div>
              <div
                :style="
                  item.survey == 1
                    ? 'background:#979797'
                    : item.survey == 2
                    ? 'background:#16ca4d'
                    : item.survey == 3
                    ? 'background:#fa4d08'
                    : item.survey == 4
                    ? 'background:#088ffa'
                    : item.survey == 7
                    ? 'background:#16ca4d'
                    : 'background:#979797'
                "
              >
                {{
                  item.survey == 1
                    ? "未背调"
                    : item.survey == 2
                    ? "背调中"
                    : item.survey == 3
                    ? "被拒绝"
                    : item.survey == 4
                    ? "已背调"
                    : item.survey == 7
                    ? "待支付"
                    : "未背调"
                }}
              </div>
              <div
                :style="
                  item.status == 2 ? 'background:#00BEFF' : 'background:#96a5aa'
                "
              >
                {{
                  item.status == 1
                    ? "未入职"
                    : item.status == 2
                    ? "已入职"
                    : item.status == 3
                    ? "已离职"
                    : item.status == 4
                    ? "拒绝入职"
                    : item.status == 5
                    ? "离职确认中"
                    : "获取失败"
                }}
              </div>
            </div>
            <div><span>累计职业评价：</span><span>保密</span></div>
          </div>
        </div>
      </div> -->
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        title="筛选"
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>

    <loading @loaddom="loaddom" ref="loades"></loading>
    <div class="nomsgclass"></div>
  </div>
</template>
<script>
import loading from "../../components/loading2/index";
export default {
  components: {
    loading,
  },
  data() {
    return {
      pageno: 0,

      zystatus: "", //职业状态
      bdstatus: "", //背调状态

      allmsg: [],
      selemsg: "", //搜索条件内容
      selenum: 0, //筛选数量
      filterarray: [], //筛选数组
      selmm: "筛选",
      showPicker: false,
      columns: [
        // 第一列
        {
          values: ["全部", "未入职", "已入职", "已离职"],
          defaultIndex: 0,
        },
        // 第二列
        {
          values: [
            "全部",
            "未生成背调",
            "授权进行中",
            "授权被拒绝",
            "已生成背调",
          ],
          defaultIndex: 0,
        },
      ],
    };
  },
  created(){
    // this.selemeth();
    if(this.$route.query.zystatus){
      this.zystatus = this.$route.query.zystatus;
      this.bdstatus = "";
      this.selemeth();
    }
  },
  methods: {
    //点击筛选条件
    onConfirm(msg) {
       
      if (msg[0] == "全部") {
        this.zystatus = "";
      } else if (msg[0] == "未入职") {
        this.zystatus = "1";
      } else if (msg[0] == "已入职") {
        this.zystatus = "2";
      } else if (msg[0] == "已离职") {
        this.zystatus = "3";
      }

      if (msg[1] == "全部") {
        this.bdstatus = "";
      } else if (msg[1] == "未生成背调") {
        this.bdstatus = "1";
      } else if (msg[1] == "授权进行中") {
        this.bdstatus = "2";
      } else if (msg[1] == "授权被拒绝") {
        this.bdstatus = "3";
      } else if (msg[1] == "已生成背调") {
        this.bdstatus = "4";
      }
      this.showPicker = false;
      this.selemeth();
    },
    tometh(item) {
      this.$router.push({
        path: "/profilemsg",
        query: {
          id: btoa(item.id),
          l_id: btoa(item.l_id),
        },
      });
    },
    selemeth() {
      this.pageno = 0;
      this.loaddom();
    },
    filtermeth() {},
    loaddom() {
      this.$refs.loades
        .loadhttpes("/firm/v1/profile/index", {
          status: this.zystatus,
          n_m: this.selemsg,
          entry_time: "",
          reqType: "profile",
          survey: this.bdstatus,
          page: ++this.pageno,
          article: 15,
        })
        .then((res) => {
           
          this.allmsg = res.arraymsg;
        });
    },
  },
};
</script>
<style scoped>
.addyuan {
  font-size: 0.3rem;
  line-height: 0.48rem;
  color: #333333;
  display: flex;
  align-items: center;
}
.addyuan > :first-child {
  margin-right: 0.13rem;
}
.themsgdd8 {
  color: #222222;
}
.themsgdd7 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #a8a8a8;
  margin-top: 0.18rem;
}
.themsgdd6 {
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #a8a8a8;
}
.themsgdd5 > :first-child {
  font-size: 0.18rem;
  line-height: 0.25rem;
  color: #ffffff;
  padding: 0.05rem 0.1rem;
  background-color: #5fcd86;
  border-radius: 0.08rem;
  margin-right: 0.11rem;
}
.themsgdd5 > :nth-child(2) {
  font-size: 0.18rem;
  line-height: 0.25rem;
  color: #ffffff;
  padding: 0.05rem 0.1rem;
  background-color: #00beff;
  border-radius: 0.08rem;
}
.themsgdd2_2 {
  width: 100%;
}
.themsgdd4 {
  display: flex;
  align-items: baseline;
}
.themsgdd4 > :first-child {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #222222;
  margin-right: 0.09rem;
}
.themsgdd4 > :nth-child(2) {
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #cccccc;
}
.themsgdd4,
.themsgdd5 {
  display: flex;
}
.themsgdd3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.12rem;
}
.themsgdd {
  background-color: #ffffff;
  border-radius: 0.1rem;
  padding: 0.32rem 0.23rem;
  margin-bottom: 0.24rem;
}
.themsgdd1 {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 0.18rem;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dddddd;
}
.themsgdd2 {
  min-width: 1.07rem;
  max-width: 1.07rem;
  height: 1.07rem;
  border-radius: 50%;
  margin-right: 0.3rem;
}
.nomsgclass {
  height: 0.3rem;
}
.bottomclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.22rem;
}
.bottomclass > :nth-child(2) > span:first-child {
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #a8a8a8;
}
.bottomclass > :first-child {
  display: flex;
  align-items: center;
}
.bottomclass > :first-child > :first-child {
  font-size: 0.18rem;
  line-height: 0.25rem;
  color: #ffffff;
  border-radius: 0.08rem;
  background-color: #5fcd86;
  padding: 0.05rem 0.1rem;
  margin-right: 0.11rem;
}
.bottomclass > :first-child > :nth-child(2) {
  font-size: 0.18rem;
  line-height: 0.25rem;
  color: #ffffff;
  border-radius: 0.08rem;
  background-color: #00beff;
  padding: 0.05rem 0.1rem;
}
.naticlass {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.naticlass > :first-child > :first-child {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #222222;
  margin-right: 0.09rem;
}
.naticlass > :first-child > :nth-child(2) {
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #cccccc;
}
.naticlass > :nth-child(2) {
  color: #a8a8a8;
  font-size: 0.24rem;
  line-height: 0.33rem;
}
.rightmsg {
  width: 100%;
}
.msgclass2 {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.1rem;
  margin-bottom: 0.21rem;
  padding: 0.46rem 0.23rem;
}
.msgimg {
  width: 1.37rem;
  max-height: 1.37rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.3rem;
}
.msgimg img {
  width: 100%;
  height: 100%;
}
.msgclass {
  margin: 0.24rem;
}
.seleclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.3rem;
  line-height: 0.42rem;
  padding: 0.14rem 0.21rem;
  background-color: #ffffff;
  border-radius: 0.1rem;
  width: 73%;
}
.seleclass input {
  border: none;
}
.sh2 {
  padding: 0.13rem 0.32rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.42rem;
  background: #eeeeee;
  border-radius: 0.1rem;
  position: relative;
}
.sh2 img {
  position: absolute;
  width: 0.12rem;
  bottom: 0.11rem;
  right: 0.13rem;
}
.tiaojian {
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  text-align: center;
  line-height: 0.3rem;
  border-radius: 50%;
  background-color: #ff5f19;
  color: #ffffff;
  font-size: 0.2rem;
  top: -0.1rem;
  right: -0.1rem;
}
.topmsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.26rem 0.24rem;
}
</style>
